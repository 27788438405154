export const appPreview =
  'https://res.cloudinary.com/duwovvtle/image/upload/v1739215214/website/app-phones-preview.webp';
export const appStoreButton =
  'https://res.cloudinary.com/duwovvtle/image/upload/v1739215219/website/app-store-button.webp';
export const googlePlayButton =
  'https://res.cloudinary.com/duwovvtle/image/upload/v1739215219/website/google-play-button.webp';
export const iconAndNameWhite =
  'https://res.cloudinary.com/duwovvtle/image/upload/v1739215214/website/logo-and-name-white.webp';
export const iconAndNameSlate =
  'https://res.cloudinary.com/duwovvtle/image/upload/v1739215214/website/logo-and-name-slate.webp';

export const donnellansIcon = 'https://i.ibb.co/92rMBWq/donnellans.webp';
export const hynesIcon = 'https://i.ibb.co/6Nttxvn/hynes.png';
export const smithsIcon = 'https://i.ibb.co/yBD3sFL/smiths.webp';
export const theRavenIcon = 'https://i.ibb.co/bs9pNCT/the-raven.webp';
export const theShamrockIcon = 'https://i.ibb.co/Cw4YXqf/the-shamrock.webp';
