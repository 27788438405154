import { useMapsLibrary } from '@vis.gl/react-google-maps';
import React, { useEffect, useRef, useState } from 'react';
import Turnstile from 'react-turnstile';
import styled from 'styled-components';
import { sendEmail } from '../../../../app/api';
import { spacing } from '../../../../app/components/styled';
import { ActionButton } from '../../../../app/features/shared/components/styled';
import {
  FormWrapper,
  StyledForm,
  StyledInput,
  StyledLabel,
  StyledRadioButton,
  StyledTextArea,
} from '../../../../app/features/shared/components/styled-form';
import { turnstileSiteKey } from '../../../../shared-constants';
import { PlaceType } from '../../../types';

interface Props {
  setSubmitted: (submitted: boolean) => void;
}

export const FeedbackForm = ({ setSubmitted }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const placesLib = useMapsLibrary('places');
  const [autocomplete, setAutocomplete] = useState<PlaceType | null>(null);
  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(
    null
  );
  const [comment, setComment] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [owner, setOwner] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!placesLib || !inputRef.current) {
      return;
    }

    const options: google.maps.places.AutocompleteOptions = {
      types: ['establishment'],
      componentRestrictions: { country: 'can' },
      fields: ['name'],
    };

    setAutocomplete(new placesLib.Autocomplete(inputRef.current, options));
  }, [placesLib]);

  useEffect(() => {
    if (!autocomplete) {
      return;
    }

    autocomplete.addListener('place_changed', () => {
      setPlace(autocomplete.getPlace());
    });
  }, [autocomplete]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const submit = async () => {
      if (place && place.name) {
        const content = `
Name: ${place.name}.

Submitter email: ${email}
Business owner: ${owner ? 'yes' : 'no'}.
  
${comment !== undefined ? `Comments: ${comment}` : ''}
  
          `;

        const successful = await sendEmail({
          subject: `Feedback - ${place.name}`,
          content,
          turnstileToken,
        });
        if (successful) {
          setSubmitted(true);
        } else {
          setError('There was an error submitting the suggestion.');
        }
      }
    };

    submit();
  };

  return (
    <FormWrapper>
      <StyledForm onSubmit={handleSubmit} style={{ marginBottom: spacing.lg }}>
        <StyledLabel htmlFor='comments-input'>Comments*</StyledLabel>
        <StyledTextArea
          id='comments-input'
          onChange={(e) => setComment(e.target.value)}
        />
        <StyledLabel htmlFor='business-name-input'>Business Name</StyledLabel>
        <StyledInput
          id='business-name-input'
          placeholder='Business Name'
          ref={inputRef}
        />
        <StyledLabel htmlFor='email-input'>Email*</StyledLabel>
        <StyledInput
          id='email-input'
          placeholder='Your Email Address*'
          type='email'
          onChange={(e) => setEmail(e.target.value)}
        />
        <p>Do You Work for This Business?</p>
        <StyledRadioButton>
          <input
            id='owner-yes'
            type='radio'
            value='Yes'
            checked={owner}
            onChange={() => setOwner(true)}
          />
          <label htmlFor='owner-yes'>Yes</label>
        </StyledRadioButton>
        <StyledRadioButton>
          <input
            id='owner-no'
            type='radio'
            value='No'
            checked={!owner}
            onChange={() => setOwner(false)}
          />
          <label htmlFor='owner-no'>No</label>
        </StyledRadioButton>
        <br />
        <p></p>

        {error && <span style={{ color: 'red' }}>{error}</span>}
        {!!turnstileToken ? (
          <ActionButton type='submit' disabled={!comment || !email}>
            Submit
          </ActionButton>
        ) : (
          <TurnstileWrapper>
            <Turnstile
              sitekey={turnstileSiteKey}
              onVerify={(token) => setTurnstileToken(token)}
            />
          </TurnstileWrapper>
        )}
      </StyledForm>
    </FormWrapper>
  );
};

const TurnstileWrapper = styled.div`
  margin-top: ${spacing.lg};
`;
