import React, { RefObject, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  fontWeight,
  spacing,
} from '../../../app/components/styled';
import {
  appStoreButton,
  googlePlayButton,
  iconAndNameSlate,
  iconAndNameWhite,
} from '../../images';
import { SectionWrapper } from '../../shared/components/section-wrapper';

interface Props {
  coverSectionRef?: RefObject<HTMLDivElement>;
}

export const Header = ({ coverSectionRef }: Props) => {
  const [scrolledDown, setScrolledDown] = useState(false);
  const [sectionHeight, setSectionHeight] = useState<number | null>(null);
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    const scrollListener = () => {
      setScrolledDown(window.scrollY - 100 > 0);

      if (sectionHeight && window.scrollY > sectionHeight) {
        setShowButtons(true);
      } else if (!sectionHeight || window.scrollY < sectionHeight) {
        setShowButtons(false);
      }
    };

    window.addEventListener('scroll', scrollListener);

    return () => window.removeEventListener('scroll', scrollListener);
  }, [sectionHeight]);

  useEffect(() => {
    if (coverSectionRef?.current) {
      const height =
        coverSectionRef?.current?.getBoundingClientRect().height ?? null;
      setSectionHeight(height);
    }
  }, [coverSectionRef?.current]);

  return (
    <SectionWrapper
      backgroundColor={scrolledDown ? 'white' : colors.blue}
      transitionBackground={scrolledDown}
      maxWidth='1800px'
      isSticky={true}
    >
      <ContentWrapper>
        <LogoContainer>
          <Link to={'/'}>
            <Logo
              alt='Vibe logo of a city skyline and the Vibe name'
              src={scrolledDown ? iconAndNameSlate : iconAndNameWhite}
              width='120px'
              height='40px'
            />
          </Link>
        </LogoContainer>
        <Nav>
          {/* <NavItem>
            <Link to='/'>Home</Link>
          </NavItem> */}
          {/* TODO */}
          {/* <NavItem>
            <Link to='business/login'>Login</Link>
          </NavItem>
          <NavItem>
            <Link to='business/register'>Register</Link>
          </NavItem> */}
        </Nav>
        {showButtons && (
          <ButtonsContainer>
            <a href='https://apps.apple.com/ca/app/vibe-happy-hours-and-more/id6705122593'>
              <AppStoreButton
                id='header-app-store-button'
                alt='App Store download button image'
                src={appStoreButton}
              />
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.burnspbrian.happyhour'>
              <GooglePlayButton
                id='header-google-play-button'
                alt='Google Play Store download button image'
                src={googlePlayButton}
              />
            </a>
          </ButtonsContainer>
        )}
      </ContentWrapper>
    </SectionWrapper>
  );
};

const ContentWrapper = styled.div`
  width: 100%;
  padding-top: ${spacing.md};
  display: flex;
  flex-direction: row;
`;

const LogoContainer = styled.div``;

const Logo = styled.img`
  max-width: 120px;

  @media ${desktopBreakpoint} {
    max-width: 180px;
    width: 100%;
    height: 100%;
  }
`;

const Nav = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const NavItem = styled.li`
  display: inline;
  margin-right: ${spacing.md};
  padding: ${spacing.md};

  a {
    text-decoration: none;
    color: white;
    font-size: 20px;
    font-weight: ${fontWeight.medium};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${spacing.sm};
`;

const AppStoreButton = styled.img`
  margin-right: ${spacing.md};
  object-fit: cover;
  border-radius: 8px;
  border-width: 0.5px;
  border-style: solid;
  border-color: white;
  max-height: 30px;

  @media ${desktopBreakpoint} {
    max-height: 50px;
  }
`;

const GooglePlayButton = styled.img`
  margin-left: ${spacing.md};
  object-fit: cover;
  border-radius: 8px;
  border-width: 0.5px;
  border-style: solid;
  border-color: white;
  max-height: 30px;

  @media ${desktopBreakpoint} {
    max-height: 50px;
  }
`;
