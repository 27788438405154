import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  fontSize,
  spacing,
} from '../../../components/styled';

export const LegalTextContainer = styled.div`
  margin-bottom: ${spacing.lg};
  align-self: end;
  color: ${colors.slate};
  padding: 0 ${spacing.xl};

  @media ${desktopBreakpoint} {
    padding: ${spacing.xl};
    width: 80%;
    padding-right: 0;
  }
`;

export const HorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ActionButton = styled(Button)<{
  cancel?: 'true' | 'false';
  component?: React.ElementType; // Only used by the PDF upload button to provide the prop
}>`
  && {
    padding-left: ${spacing.lg};
    padding-right: ${spacing.lg};
    margin-top: ${spacing.lg};
    background-color: ${colors.blue};
    color: white;

    ${({ cancel }) =>
      cancel === 'true' &&
      `
      background-color: white;
      color: ${colors.blue};
      border: 1px ${colors.blue} solid;
    `};

    &:disabled {
      background-color: ${colors.greyBlue};
      color: white;
    }

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const StyledTabButton = styled(Button)<{ $active: boolean }>`
  && {
    color: ${colors.blue};
    padding-left: ${spacing.lg};
    padding-right: ${spacing.lg};
    flex-direction: column;
    border-radius: 0;
    box-shadow: none;
    background-color: ${colors.paleGrey};
    margin: 0 ${spacing.md};

    ${({ $active }) =>
      $active &&
      `
    border-bottom: solid 3px ${colors.blue};
    margin-bottom: -3px;
    font-weight: bold;
  `};

    &:hover {
      box-shadow: none;
      opacity: 0.8;
      background-color: ${colors.paleGrey};
      border-bottom: solid 3px ${colors.blue};
      margin-bottom: -3px;
    }
  }
`;

export const StyledTabLink = styled(Link)<{ $active: boolean }>`
  && {
    position: inherit;
    color: ${colors.blue};
    padding: ${spacing.md} ${spacing.lg};
    text-decoration: none;
    font-size: ${fontSize.textLarge};

    ${({ $active }) =>
      $active &&
      `
    border-bottom: solid 3px ${colors.blue};
    border-radius: 0;
    margin-bottom: -3px;
    font-weight: bold;
  `};

    &:hover {
      box-shadow: none;
      opacity: 0.8;
      border-bottom: solid 3px ${colors.blue};
      margin-bottom: -3px;
    }
  }
`;
