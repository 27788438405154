import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import styled from 'styled-components';
import { colors } from './styled';
import { CustomHeading3 } from './styled/headings';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  align-items: center;
  color: ${colors.blue};

  svg {
    color: ${colors.blue} !important;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin-left: auto;
  margin-right: auto;
`;

const Message = styled(CustomHeading3)`
  margin-bottom: 20px;
`;

export const Loader = () => {
  return (
    <Wrapper>
      <Message>Loading...</Message>
      <StyledCircularProgress />
    </Wrapper>
  );
};
