import React, { lazy, Suspense, useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Loader } from '../../app/components/loader';
import { Home } from '../routes/home/home';
import { HomeWrapper } from './components/home-wrapper';

const FeedbackPage = lazy(() => import('../routes/feedback'));
const LegalPage = lazy(() => import('../routes/legal'));
const PrivacyPolicyPage = lazy(
  () => import('../routes/legal/privacy-policy/privacy-policy')
);
const SupportPage = lazy(() => import('../routes/legal/support'));
const TermsAndConditionsPage = lazy(
  () => import('../routes/legal/terms-and-conditions')
);

export const WebsiteRoot = () => {
  const coverSectionRef = useRef<HTMLDivElement>(null);

  return (
    <BrowserRouter basename={'/'}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path=''
            element={<HomeWrapper coverSectionRef={coverSectionRef} />}
          >
            <Route index element={<Home ref={coverSectionRef} />} />
            <Route path='feedback' element={<FeedbackPage />} />
            <Route path='legal' element={<LegalPage />} />
            <Route
              path='legal/privacy-policy'
              element={<PrivacyPolicyPage />}
            />
            <Route path='legal/support' element={<SupportPage />} />
            <Route path='legal/terms' element={<TermsAndConditionsPage />} />
            <Route path='*' element={<Home ref={coverSectionRef} />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
